import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import { THE_BRIDE } from '@/constants';
import { BG_PRIMARY } from '@/constants/colors';
import { BG_DESKTOP_VIEW } from '@/constants/assets';
import WithAnimation from '@components/Common/WithAnimation';

function LeftContent() {
  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
    >
      <Box
        padding="42px"
        background={`linear-gradient(360deg, rgb(0 0 255 / 0%), ${BG_PRIMARY} 90%)`}
      >
        <WithAnimation>
          <Text letterSpacing="4px" fontSize="sm">
            THE WEDDING OF
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Heading
            fontFamily="body"
            margin="24px 0"
            fontWeight="normal"
            size="3xl"
            color="mainColorText"
            dangerouslySetInnerHTML={{ __html: THE_BRIDE }}
          />
        </WithAnimation>
        <WithAnimation>
          <Text fontStyle="italic" maxWidth="50%">
            “True Love Stories Never Have Endings” <br />— Richard Bach
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default LeftContent;
