// Favicon
import Fav_Image from '@/components/Layout/assets/favicon.png';
export const INVITATO_FAVICON = Fav_Image;

// BG Image Design
export const BG_WISHLIST = `https://ik.imagekit.io/adelinejune/michaelmonica/Cover%20Wishes.jpg?updatedAt=1698662593296`;
export const BG_RSVP = `https://ik.imagekit.io/adelinejune/michaelmonica/Cover%20RSVP?updatedAt=1698471325739`;
export const BG_COVER = `https://ik.imagekit.io/adelinejune/michaelmonica/Cover%20Potrait.jpg?updatedAt=1698384821508`;
export const BG_FOOTER = `https://ik.imagekit.io/adelinejune/michaelmonica/Footer.jpg?updatedAt=1698662811429`;
export const BG_GIFTCARD = `https://ik.imagekit.io/adelinejune/michaelmonica/BG%20Gift?updatedAt=1698471464019`;
export const BG_COUNTING = `https://user-images.githubusercontent.com/26963539/158041317-be2709ec-e3a4-46b3-b7c0-2cc3ab970f2d.jpg`;
export const PATTERN_FLOWER = `https://ik.imagekit.io/invitatoid/template-naomirezky/bg-flower_XCiHvbJf8.png?updatedAt=1638917152091`;
export const BG_WEDDING =
  'https://ik.imagekit.io/invitatoid/template-naomirezky/bg-wedding__dMxHMJUh.jpg?updatedAt=1638917140208';
export const BG_COUPLE = `https://ik.imagekit.io/invitatoid/template-naomirezky/bg-couple_5aCNHnxMaKC.jpg?updatedAt=1638917136155`;
export const BG_DETAIL = `https://ik.imagekit.io/invitatoid/template-naomirezky/bg-detail_4RGKk2WOV.jpg?updatedAt=1638917135419`;
export const BG_START_JOURNEY = BG_FOOTER;
export const BG_LOVESTORY = BG_WISHLIST;
export const BG_FILTER = `https://ik.imagekit.io/adelinejune/michaelmonica/filter?updatedAt=1698472446563`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/adelinejune/michaelmonica/Desktop?updatedAt=1698471578019`;

// The Bride Photo
export const IMG_MAN = `https://ik.imagekit.io/adelinejune/michaelmonica/Groom.jpg?updatedAt=1698385568234`;
export const IMG_GIRL = `https://ik.imagekit.io/adelinejune/michaelmonica/Bride.jpg?updatedAt=1698385609772`;

// Cover Gallery
export const COVER_GALLERY = `https://ik.imagekit.io/adelinejune/michaelmonica/Cover%20Gallery%201.jpg?updatedAt=1698385866590`;
export const COVER_GALLERY_2 = `https://ik.imagekit.io/adelinejune/michaelmonica/Cover%20Gallery%202.jpg?updatedAt=1698385917268`;
export const COVER_GALLERY_3 = `https://ik.imagekit.io/adelinejune/michaelmonica/gallery/1I7A0130.jpg?updatedAt=1698476138103`;

// Image Fade Slide Show
export const SLIDE_1 = `https://ik.imagekit.io/adelinejune/michaelmonica/Slideshow%201.jpg?updatedAt=1698385004663`;
export const SLIDE_2 = `https://ik.imagekit.io/adelinejune/michaelmonica/Slideshow%202.jpg?updatedAt=1698385004621`;
export const SLIDE_3 = `https://ik.imagekit.io/adelinejune/michaelmonica/Slideshow%203.jpg?updatedAt=1698385004798`;
export const SLIDE_4 = `https://ik.imagekit.io/adelinejune/michaelmonica/Slideshow%204.jpg?updatedAt=1698385004700`;
export const SLIDE_5 = `https://ik.imagekit.io/adelinejune/michaelmonica/Slideshow%205.jpg?updatedAt=1698385004688`;

// Dont Change With CDN Image
import ImgQRCover from '../images/qrcover.jpg';
export const IMG_QR_COVER = ImgQRCover;
